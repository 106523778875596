import Swup from 'swup'
import SwupA11yPlugin from '@swup/a11y-plugin'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import SwupFadeTheme from '@swup/fade-theme'
import SwupDebugPlugin from '@swup/debug-plugin'

export default (callback) => {
  // only init pjax if main container exists (fixes previews)
  const canInit = !!document.querySelector('#swup')

  const containers = ['#swup'].filter((item) => document.querySelector(item))

  if (!window.A17.reduceMotion && canInit && containers.length > 0) {
    let isCareerPage = false;
    if (window.location.pathname.indexOf("/careers") > -1) {
      isCareerPage = true;
    }
    const swup = new Swup({
      containers: containers,
      plugins: [
        new SwupA11yPlugin({
          contentSelector: '#content',
        }),
        new SwupBodyClassPlugin(),
        new SwupFadeTheme(),
      ],
      linkSelector: isCareerPage 
        ? null
        : 'a[href^="' +
        window.location.origin +
        '"]:not([data-no-swup]):not([href^="/contact"]):not([href^="/careers"]):not([href*="/careers/"]):not([href^="' +window.location.origin + '/about/legal/privacy-policy"]):not([href^="' +window.location.origin + '/ir-2022-redirect"]):not([href^="' +window.location.origin + '/about/ir-2022-redirect"]), a[href^="/"]:not([data-no-swup]):not([href^="/contact"]):not([href^="/careers"]):not([href*="/careers/"]):not([href^="/ir-2022-redirect"]):not([href^="/about/ir-2022-redirect"])',
    })

    swup.on('contentReplaced', (e) => {
      window.scrollTo(0, 0)
      const event = new Event('swup:page-update')
      window.dispatchEvent(event)

      //   console.log('resize event')
      const resizeEvent = new Event('setVh')
      window.dispatchEvent(resizeEvent)

      // Handle VirtualPageview Event
      if (typeof window.dataLayer === 'object') {
        let pageVars = document.querySelector('#swup').dataset
        const object = {
          'event': 'VirtualPageview',
          'virtualPageURL': window.location.pathname + window.location.search,
          'virtualPageTitle': document.title,
          'content_all_tags': pageVars.contentAllTags,
          'content_article_type': pageVars.contentArticleType,
          'content_author': pageVars.contentAuthor,
          'content_date_modified': pageVars.contentDateModified,
          'content_date_published': pageVars.contentDatePublished,
          'content_entry_type': pageVars.contentEntryType,
          'content_structural_type': pageVars.contentStructuralType,
          'global_shift': pageVars.globalShift,
          'industry': pageVars.industry,
          'industry_group': pageVars.industryGroup,
          'services': pageVars.services,
          'services_group': pageVars.serviceGroup
        };

        window.dataLayer.push(object);

        swup.log(`GTM pageview (url '${object.virtualPageURL}').`);
      } else {
        console.warn('GTM is not loaded.')
      }
    })

    return swup
  }
}
